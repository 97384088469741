<template>
  <div class="container">
    <div class="title">账户充值</div>
    <div class="content">
      <div class="form_box">
        <el-form
          ref="form"
          :model="form"
          label-width="120px"
        >
          <el-form-item label="可用余额：">
            ￥{{$store.state.userInfo?$store.state.userInfo.base.user_account:'0'}}
          </el-form-item>
          <el-form-item label="充值金额：">
            <div class="select_group">
              <div
                :class="['select_item', selectedPrice==100?'selected':'']"
                @click="clickPirce(100)"
              >
                ￥100
              </div>
              <div
                :class="['select_item', selectedPrice==500?'selected':'']"
                @click="clickPirce(500)"
              >
                ￥500
              </div>
              <div
                :class="['select_item', selectedPrice==1000?'selected':'']"
                @click="clickPirce(1000)"
              >
                ￥1000
              </div>
              <div
                :class="['select_item', selectedPrice==2000?'selected':'']"
                @click="clickPirce(2000)"
              >
                ￥2000
              </div>
              <div
                :class="['select_item', selectedPrice=='custom'?'selected':'']"
                @click="clickPirce('custom')"
              >
                <!-- min="0" -->
                <el-input
                  min="0.01"
                  type="number"
                  ref="customPrice"
                  v-if="selectedPrice=='custom'"
                  v-model="form.price"
                  placeholder="自定义金额"
                  @input="changePrice"
                  @blur="blurPrice"
                ></el-input>
                <span v-else>自定义金额</span>

              </div>
            </div>
          </el-form-item>
          <el-form-item label="支付方式：">
            <div class="select_group">
              <div
                :class="['select_item', form.pay_type==1?'selected':'']"
                @click="form.pay_type=1"
              >
                微信
              </div>
              <!-- <div
                :class="['select_item', form.pay_type==2?'selected':'']"
                @click="form.pay_type=2"
              >
                支付宝
              </div> -->

            </div>
          </el-form-item>
          <!-- <el-form-item label="账号名称：">
            {{$store.state.userInfo?$store.state.userInfo.base.user_name:''}}
          </el-form-item> -->
          <div class="btn_box">
            <el-button
              type="primary"
              @click="goPay"
            >立即充值</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <ChargeDialog ref="chargeDialog"></ChargeDialog>

  </div>
</template>

<script>
const ChargeDialog = () => import('./ChargeDialog.vue')
export default {
  // 组件名称
  name: '',
  // 局部注册的组件
  components: {
    ChargeDialog
  },
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data () {
    return {
      selectedPrice: 100,
      form: {
        pay_type: 1,
        price: 100
      }
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created () { },
  mounted () { },
  // 组件方法
  methods: {
    changePrice (val) {
      this.form.price = val.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
    },
    blurPrice () {
      console.log(this.form.price)
      if (this.form.price <= 0) {
        this.$message.error('请输入正确充值金额')
        this.form.price = 1
      }
    },
    clickPirce (type) {
      if (this.selectedPrice === type) {
        if (type === 'custom') {
          this.$nextTick(() => {
            this.$refs.customPrice.focus()
          })
        }
      } else {
        this.selectedPrice = type
        if (type === 'custom') {
          this.form.price = ''
          this.$nextTick(() => {
            this.$refs.customPrice.focus()
          })
        } else {
          this.form.price = type
        }
      }
    },
    goPay () {
      if (!this.form.price) return this.$message.error('选择自定义金额时必须填写金额')
      this.$refs.chargeDialog.openDialog({
        pay_service: this.form.pay_type,
        charge_fee: +((+this.form.price).toFixed(2))
      })
    }
  }
}
</script>

<style scoped lang="less">
.container {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  .title {
    height: 35px;
    line-height: 35px;
    border-bottom: 1px solid #ededed;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .content {
    height: calc(100% - 35px);
    .form_box {
      width: 500px;
      // height: 100px;
      // background-color: pink;
      margin: 10px auto;
      .btn_box {
        display: flex;
        justify-content: center;
        width: 100%;
      }
      .select_group {
        display: flex;
        justify-content: flex-start;

        flex-wrap: wrap;
        width: 100%;
        .select_item {
          width: 30%;
          height: 50px;
          margin-right: 2%;
          // background-color: pink;
          margin-bottom: 10px;
          text-align: center;
          line-height: 50px;
          border-radius: 5px;
          border: 1px solid #aaa;
          box-sizing: border-box;
          overflow: hidden;
          cursor: pointer;
          .el-input {
            width: 100%;
            height: 100%;
            /deep/.el-input__inner {
              width: 100%;
              height: 100%;
              border: none;
              text-align: center;
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
              }
              &[type="number"] {
                -moz-appearance: textfield;
              }
            }
          }
        }
        .selected {
          position: relative;
          border-color: rgb(12, 130, 209);
          color: rgb(26, 153, 238);

          &::after {
            content: "选中";
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            color: #fff;
            width: 50%;
            height: 50%;
            bottom: -4px;
            right: -20px;
            font-size: 12px;
            background-color: rgb(12, 130, 209);
            transform: rotate(-45deg) scale(0.8);
          }
        }
      }
    }
  }
}
</style>
